<template>
  <div>
    <el-upload
      action="/shop/fileservice/uploadFile"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleAvatarSuccess"
      :headers="token"
      :data="{ moduleType: moduleType }"
      :file-list="fileList"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" size="tiny">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/util/auth";
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      token: {},
    };
  },
  props: {
    moduleType: {
      type: String,
      default: () => {
        return "01";
      },
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    this.token = {
      "Blade-Auth": "bearer " + getToken(),
    };
  },
  methods: {
    //上传营业执照
    handleAvatarSuccess(res) {
      this.fileList.push({
        url: res.data[0].filePath,
        name: res.data[0].fileNewName,
      });
    },
    // 上传前判断
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 移除
    handleRemove(file) {
      let url = file.response ? file.response.data[0].filePath : file.url;
      for (let x = 0; x < this.fileList.length; x++) {
        if (this.fileList[x].url == url) {
          this.fileList.splice(x, 1);
        }
      }
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 获取列表数据
    getFileList() {
      let fileList = [];
      for (let x = 0; x < this.fileList.length; x++) {
        fileList.push({
          logo: this.fileList[x].url,
          sort: x + 1,
        });
      }
      return fileList;
    },
  },
  computed: {
    fileList() {
      let list = [];
      for (let x = 0; x < this.list.length; x++) {
        list.push({
          name: this.list[x].id,
          url: this.list[x].logo,
        });
      }
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>