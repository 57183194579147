<template>
  <div>
    <relative-content>
      <div slot="menu">
        <el-button
          type="primary"
          icon="el-icon-back"
          size="small"
          @click="goBack()"
          >返回列表</el-button
        >
        <span class="F16 Mlf10"> 商品信息 </span>
      </div>
      <div slot="title">商品发布</div>
      <div slot="relat">
        <div class="relat Plf40 Prt40">
          <div class="Ptp20 jicu">
            <el-row :gutter="1">
              <el-col class="text" :span="3">
                <div>商品标题</div>
              </el-col>
              <el-col :span="21">
                <el-input
                  placeholder="最多输入30个汉字（60字符）"
                  v-model="basicData.goodsTitle"
                >
                </el-input>
              </el-col>
            </el-row>
            <div class="F12 main-text Plf120 Ptp10">
              建议将以下推荐词添加至标题中以提升您的商品表达，请只添加符合您商品描述的内容：
              <el-button plain size="mini">水工材料</el-button>
              <el-button plain size="mini">电工材料</el-button>
              <el-button plain size="mini">木工材料</el-button>
              <el-button plain size="mini">瓦工材料</el-button>
              <el-button plain size="mini">五金工具</el-button>
            </div>
          </div>
          <div class="picture">
            <div class="label Ptp20">
              上传商品主图
              <span class="Mlf20 main-text"
                >上传高清正面商品主图，可加速智能识别及填充商品信息，帮您智能选择发布类目，上传清晰商品正面图</span
              >
            </div>
            <div class="imgform Ptp10 Mlf120">
              <upload
                ref="upload"
                :list="basicData.logoList"
                moduleType="01"
              ></upload>
            </div>
          </div>
          <diV class="goodtype Ptp10">
            <div>
              确定商品类目
              <span class="orange Plf20">请确认或修改商品类目</span>
            </div>
            <div class="type Mlf120 Pbm20 Ptp20">
              <el-cascader
                :options="goodsTypeList"
                :props="{
                  checkStrictly: true,
                  value: 'id',
                  label: 'categoryName',
                }"
                clearable
                v-model="basicData.goodsTypeId"
                style="width: 95%"
                filterable
              ></el-cascader>
              <!-- <goodsType ref="goodsType" :tree="goodsTypeList"></goodsType> -->
            </div>
          </diV>
        </div>
      </div>
      <div slot="nextstep" class="button-group">
        <el-button type="primary" @click="nextStep()" :loading="loading"
          >下一步</el-button
        >
      </div>
    </relative-content>
  </div>
</template>

<script>
import RelativeContent from "@/components/relativeContent";
import upload from "@/components/upload/singleUpload";
import { mapGetters } from "vuex";

import { saveOrUpdate, getList, getGoodsInfoById } from "@/api/product";

export default {
  components: {
    RelativeContent,
    upload,
  },
  data() {
    return {
      basicData: {
        // 商品标题
        goodsTitle: "",
        // logo主图
        logoList: [],
        // 商品类目ID
        goodsTypeId: "",
      },
      // 商品类目数据
      goodsTypeList: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    // 获取商品类目数据
    this.getGoodsType();
    if (this.$route.query.id) {
      this.getGoodsInfoById(this.$route.query.id);
    }
  },
  methods: {
    // 根据商品ID获取整个商品的信息
    getGoodsInfoById(id) {
      getGoodsInfoById(id).then((res) => {
        this.basicData = res.data;
      });
    },
    // 获取商品类目数据
    getGoodsType() {
      getList().then((res) => {
        let goodsTypeList = res.data;
        for(let node of goodsTypeList) {
          this.recursive(node);
        }
        this.$set(this, "goodsTypeList", goodsTypeList);
      });
    },
    // 返回上一页
    goBack() {
      let query = this.$route.query;
      delete query.id;
      this.$router.push({
        name: "GoodsList",
        query: query,
      });
    },
    nextStep() {
      this.$nextTick(() => {
        let basicData = Object.assign({}, this.basicData);
        basicData.logoList = this.$refs.upload.getFileList();
        basicData.userId = this.userInfo.id;
        basicData.goodsTypeId =
          typeof basicData.goodsTypeId != "string"
            ? basicData.goodsTypeId.pop()
            : basicData.goodsTypeId;
        this.loading = true;
        saveOrUpdate(basicData).then((res) => {
          this.loading = false;
          if (res.success) {
            let query = this.$route.query;
            delete query.id;
            this.$router.push({
              name: "BasicInformation",
              query: {
                ...query,
                id: res.data.id
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    recursive(node) {
      // 如果存在孩子节点，则递归访问孩子节点
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          this.recursive(child);
        }
      } else {
        delete node.children
      }
    },
  },
};
</script>
<style scoped lang="scss">
.button-group .el-button {
  padding-left: 61px;
  padding-right: 61px;
}

ul {
  padding: 0;
}
li {
  padding-left: 10px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  cursor: pointer;
}
.checked {
  background-color: #87cefa;
}
.relat {
  .jicu {
    .text {
      line-height: 40px;
    }
    .el-button {
      padding: 5px 5px;
    }
  }
  .form {
    .typepropertyform {
      border: 1px solid #cccccc;
    }
  }
}
</style>